body {
  background-color: #fff;
}

.App {
  text-align: center;
  margin: 0 auto;
}

form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: rgb(24, 23, 23);
  width: 100%;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
}

form button {
  width: 20%;
  background-color: rgb(56, 56, 143);
}

input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: rgb(58, 58, 58);
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
}

button {
  background-color: #282c34; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


ul, li {
  text-align: left;
  list-style: none;
}

p {
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: black;
  text-align: center;
}

.message {
  display: flex;
  align-items: center;
}

.sender {
  flex-direction: row-reverse;
}

.sender p {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}
.receiver p {
  background: #e5e5ea;
  color: black;
}